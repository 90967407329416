import {Routes} from '@angular/router'

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('@pages/home/home.component').then(r => r.HomeComponent),
    data: {prerender: true},
  },
  {
    path: 'edit/:editLink',
    loadComponent: () => import('@pages/edit/edit.component').then(r => r.EditComponent),
  },
  {
    path: 'v/:viewLink',
    loadComponent: () => import('@pages/view/view.component').then(r => r.ViewComponent),
  },
  {
    path: 'v/:viewLink/:slug',
    loadComponent: () => import('@pages/view/view.component').then(r => r.ViewComponent),
  },
  {
    path: 'r/:viewLink',
    loadComponent: () => import('@pages/read/read.component').then(r => r.ReadComponent),
  },
  {
    path: 'r/:viewLink/:slug',
    loadComponent: () => import('@pages/read/read.component').then(r => r.ReadComponent),
  },
  {
    path: 'search',
    loadComponent: () => import('@pages/search/search.component').then(r => r.SearchComponent),
  },
  {
    path: 'public-files/:page',
    loadComponent: () => import('@pages/public-files/public-files.component').then(r => r.PublicFilesComponent),
  },
  {
    path: 'profile',
    loadComponent: () => import('@pages/profile/profile.component').then(r => r.ProfileComponent),
  },
  {
    path: 'about',
    loadChildren: () => import('@pages/about/about.module').then(r => r.AboutModule),
  },
  {
    path: 'partners',
    loadComponent: () => import('@pages/partners/partners.component').then(r => r.PartnersComponent),
  },
  {
    path: 'blog',
    loadComponent: () => import('@pages/blog/blog.component').then(r => r.BlogComponent),
  },
  {
    path: 'blog/:id/:slug',
    loadComponent: () => import('@pages/post/post.component').then(r => r.PostComponent),
  },
]
